import React, { useEffect, useRef, useState } from "react";
import style from "./Asessments.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import Autocomplete from "@mui/material/Autocomplete";
import { TbMessageQuestion, TbUnderline } from "react-icons/tb";
import { Alert, Checkbox, TextField, Tooltip, Switch } from "@mui/material";
import {
  MdDelete,
  MdOutlineQuestionAnswer,
  MdAddCircleOutline,
  MdAdd,
  MdFormatStrikethrough,
  MdFormatItalic,
  MdFormatBold,
  MdClose,
  MdOutlineMic,
} from "react-icons/md";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { SyncLoader } from "react-spinners";
import { GrChapterAdd, GrClear, GrUpdate } from "react-icons/gr";
import Loader from "../Loader/Loader";
import { IoCreateSharp, IoSettingsOutline } from "react-icons/io5";
import { PiListPlusFill } from "react-icons/pi";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Snackbar from "@mui/material/Snackbar";
import InputAdornment from "@mui/material/InputAdornment";
import { IoMdCloudUpload } from "react-icons/io";
// import Whiteboard from '../WhiteBoard/WhiteBoard';
import { BiClipboard } from "react-icons/bi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ImageUploader from "react-quill-image-uploader";
import ImageResize from "quill-image-resize";

Quill.register("modules/imageResize", ImageResize);

const Asessments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const textFieldRef = useRef(null);

  const state = location.state;
  console.log(state, "topicDetail");
  const orgId = state.organization;
  const mainTopicId = state.mainTopic;
  const mainCategoryPath =
    state.mainCategory.length > 10
      ? state.mainCategory.substring(0, 10) + "..."
      : state.mainCategory;
  const subcategoryPath = state.subcategories;

  const [topics, setTopics] = useState([]);
  const [singleTopicName, setSingleTopicName] = useState("");
  const [singleTopicId, setSingleTopicId] = useState("");
  const [adminId, setAdminId] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [content, setContent] = useState("");
  const [blankAnswers, setBlankAnswers] = useState({});
  const [questionType, setQuestionType] = useState("");
  const [fillTheBlankView, setFillTheBlankView] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [multipleChoiceView, setMultipleChoiceView] = useState(false);
  const [options, setOptions] = useState(["", "", "", ""]);
  const [questionValue, setQuestionValue] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [ordinary, setOrdinary] = useState(false);
  const [firstOptionSet, setFirstOptionSet] = useState(["", "", "", ""]);
  const [secondOptionSet, setSecondOptionSet] = useState(["", "", "", ""]);
  const [matchAnswerValues, setMatchAnswerValues] = useState(["", "", "", ""]);
  const [matchValueView, setMatchValueView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [questionId, setQuestionId] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [initiallySelectedQuestion, setInitiallySelectedQuestion] = useState(1);
  const [mainLoading, setMainLoading] = useState(true);
  const [ordinaryAnswer, setOrdinaryAnswer] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [questionDeleteView, setQuestionDeleteView] = useState(false);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [strikeThrough, setStrikeThrough] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [activeButtons, setActiveButtons] = useState({
    bold: false,
    italic: false,
    strikethrough: false,
    underline: false,
  });
  const [isCheckedLearner, setIsCheckedLearner] = useState(false);
  const [isCheckedMentor, setIsCheckedMentor] = useState(false);
  const [isCheckedOther, setIsCheckedOther] = useState(false);
  const [settingView, setSettingView] = useState(false);
  // const [examTime, setExamTime] = useState("");
  const [correctMark, setCorrectMark] = useState("");
  const [inCorrectMark, setInCorrectMark] = useState("");
  const [questionCreationSuccess, setQuestionCreationSuccess] = useState(false);
  const [dictationView, setDictationView] = useState(false);
  const [validation, setValidation] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [settingsValidation, setSettingsValidation] = useState(false);
  const [matchOptionImageView, setMatchOptionImageView] = useState(false);
  const [firstOptionSetImage, setFirstOptionSetImage] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [secondOptionSetImageText, setSecondOptionSetImageText] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [imageMatchAnswerValues, setImageMatchAnswerValues] = useState([
    "",
    "",
    "",
    "",
  ]);
  // const[whiteBoradShow,setWhiteBoardShow]=useState(false)
  // const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)
  const [crossWordPuzzle, setCrossWordPuzzle] = useState(false);
  const [closing, setClosing] = useState(false);
  const [recordings, setRecordings] = useState([
    { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
  ]);
  const [grid, setGrid] = useState(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, () => ({
        cluevalue: "",
        clueNumber: null,
        clueID: "",
      }))
    )
  );
  const [cluesAcross, setCluesAcross] = useState([]);
  const [cluesDown, setCluesDown] = useState([]);
  const [selectingCell, setSelectingCell] = useState(false);
  const [selectedClueIndex, setSelectedClueIndex] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const [mediaCollection, setMediaCollection] = useState([]);
  const [mediaPopup, setMediaPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedImageOptionIndex, setSelectedImageOptionIndex] = useState(
    null
  );
  const [explanation, setExplanation] = useState("");

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
        [{ color: [] }, { background: [] }],
      ],
      handlers: {
        image: ImageUploader.handler,
      },
    },
    imageResize: {},
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "color",
    "background",
  ];

  const generateButtonStyles = (type) => {
    switch (type) {
      case "fill in the blanks":
        return {
          background: "#fffce5",
          color: "#c7d13a",
          border: "2px solid #d7d67c",
        };
      case "multiple choices":
        return {
          background: "#f8eafe",
          color: "#d466f6",
          border: "2px solid #e1b7ff",
        };
      case "multiple choice":
        return {
          background: "#f8eafe",
          color: "#d466f6",
          border: "2px solid #e1b7ff",
        };
      case "ordinary":
        return {
          background: "#fbdbf9",
          color: "#a43faa",
          border: "2px solid #d77cc2",
        };
      case "Match the following":
        return {
          background: "#e3efff",
          color: "#007bff",
          border: "2px solid #b0d2ff",
        };
      case "dictation":
        return {
          background: "#fce0cc",
          color: "#e07426",
          border: "2px solid #f5ab76",
        };
      case "Match the following with image":
        return {
          background: "#c4f2f2",
          color: "#02d4d4",
          border: "2px solid #02d4d4",
        };
      case "cross word puzzle":
        return {
          background: "#f0fcd4",
          color: "#abf500",
          border: "2px solid #abf500",
        };
      default:
        return {}; // Set defaults if type not found
    }
  };

  const handleExplanationChange = (html) => {
    setExplanation(html);
  };

  //setting comment toggle
  const handleLearnerSwitchChange = () => {
    setIsCheckedLearner(!isCheckedLearner);
  };
  const handleMentorSwitchChange = () => {
    setIsCheckedMentor(!isCheckedMentor);
  };
  const handleOtherSwitchChange = () => {
    setIsCheckedOther(!isCheckedOther);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  //Fill the blank question handling sections {

  //---Blank Creation

  const handleAddBlankClick = () => {
    let newBlankCount = 1;

    if (Object.keys(blankAnswers).length > 0) {
      const maxBlankNumber = Math.max(
        ...Object.keys(blankAnswers)
          .filter((key) => key.startsWith("[blank "))
          .map((key) => parseInt(key.match(/\d+/)[0]))
      );

      newBlankCount = maxBlankNumber + 1;
    }

    const newBlankName = `[blank ${newBlankCount}]`;

    if (textFieldRef.current) {
      const selectionStart = textFieldRef.current.selectionStart;
      const selectionEnd = textFieldRef.current.selectionEnd;
      const newContent =
        content.substring(0, selectionStart) +
        newBlankName +
        content.substring(selectionEnd);

      setContent(newContent);
      setBlankAnswers((prevAnswers) => ({
        ...prevAnswers,
        [newBlankName]: "",
      }));
    } else {
      setContent((prevQuestion) => `${prevQuestion}${newBlankName}`);
      setBlankAnswers((prevAnswers) => ({
        ...prevAnswers,
        [newBlankName]: "",
      }));
    }
  };

  //Question value formatting

  const handleToggleBold = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));
    setBold(!bold);
  };

  const handleToggleItalic = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setItalic(!italic);
  };

  const handleToggleStrikeThrough = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setStrikeThrough(!strikeThrough);
  };
  const handleToggleUnderline = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setUnderline(!underline);
  };

  //-----Blank Answer handling

  const handleBlankAnswerChange = (blankName, answer) => {
    setBlankAnswers((prevAnswers) => ({
      ...prevAnswers,
      [blankName]: answer,
    }));
  };

  //---Blank Deletion

  const handleDeleteBlank = (blankName) => {
    const updatedBlankAnswers = { ...blankAnswers };
    delete updatedBlankAnswers[blankName];
    setBlankAnswers(updatedBlankAnswers);

    const updatedContent = content.replace(blankName, "");
    setContent(updatedContent);
  };

  //----Space key clear handling

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const selectionStart = textFieldRef.current.selectionStart;
      const selectionEnd = textFieldRef.current.selectionEnd;
      Object.keys(blankAnswers).forEach((blankNumber) => {
        const blankPosition = content.indexOf(`[blank ${blankNumber}]`);
        if (
          blankPosition !== -1 &&
          blankPosition === selectionStart &&
          selectionStart === selectionEnd
        ) {
          handleDeleteBlank(blankNumber);
        }
      });
    }
  };

  //---Blur handling

  const handleBlur = () => {
    if (content.trim() === "") {
      setBlankAnswers({});
    }
  };

  //---Assessment Data Creation

  const handleFilltheBlankQuestionCreation = () => {
    // Transform blankAnswers state to match the desired structure
    const transformedAnswer = {};
    Object.keys(blankAnswers).forEach((blankName) => {
      const numericKey = blankName.match(/\d+/);
      if (numericKey) {
        transformedAnswer[numericKey[0]] = blankAnswers[blankName];
      }
    });

    // Create the assessmentData with the transformed answer
    const assessmentData = {
      question: content,
      type: questionType,
      options: [],
      answer: transformedAnswer,
      id: questionId,
    };

    console.log(assessmentData);
    // Perform the edit request with the assessmentData
    // if (examTime.trim() === "") {
    //   setErrorValue("Time is missing");
    //   setValidation(true);
    // } else
    if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      questionEdit(assessmentData);
    }
  };

  //}

  //Multiple Choice question handling sections{

  //Question change

  const handleMultipleQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----Answer Handling

  const handleMultipleAnsweChange = (index, value) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Check box value handling
  // Answers to include Multiple selected index
  const handleCheckboxChange = (index) => {
    setCorrectAnswers((prevCorrectAnswers) => {
      if (prevCorrectAnswers.includes(index)) {
        return prevCorrectAnswers.filter((i) => i !== index);
      } else {
        // Make sure to store unique indices
        return [...new Set([...prevCorrectAnswers, index])];
      }
    });
  };

  //  Answers to include only the selected index
  // const handleCheckboxChange = (index) => {
  //   setCorrectAnswers([index]);
  // };

  //----Option set creation

  const handleMultipleOptionAdd = () => {
    setOptions((prevOptions) => [...prevOptions, ""]);
  };

  //----Option set deletion

  const handleMultipleOptionDelete = (index) => {
    if (options.length > 2) {
      setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
    } else {
      setDeleteDisable(true);
    }
  };

  //---Assessment Data Creation

  const handleMulitipleQuestionCreation = () => {
    const assessmentData = {
      question: questionValue,
      type: questionType,
      options: options,
      answer: correctAnswers.map((index) => options[index]),
      id: questionId,
    };
    console.log(assessmentData);
    // if (examTime.trim() === "") {
    //   setErrorValue("Time is missing");
    //   setValidation(true);
    // } else
    if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      questionEdit(assessmentData);
    }
  };

  //}

  //Ordinary question handling sections{

  //Question change

  const handleOrdinaryQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //---Assessment Data Creation

  const handleOrdinaryQuestionCreation = () => {
    const assessmentData = {
      question: questionValue,
      type: questionType,
      options: [],
      answer: ordinaryAnswer,
      id: questionId,
    };

    console.log(assessmentData);

    // Now, newArray is a simple array containing objects with 'question' and 'id' properties.
    // if (examTime.trim() === "") {
    //   setErrorValue("Time is missing");
    //   setValidation(true);
    // } else
    if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      questionEdit(assessmentData);
    }
  };

  // }

  //Match the following question handling sections{

  //Question change

  const handleMatchQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----First Option Set handling

  const handleFirstOptionSet = (index, value) => {
    setFirstOptionSet((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //-----Second Option Set handling

  const handleSecondOptionSet = (index, value) => {
    setSecondOptionSet((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Answer set handling

  const handleMatchAnswerKey = (index, value) => {
    setMatchAnswerValues((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Options Add

  const handleMatchOptionAdd = (type) => {
    if (type === "first") {
      setFirstOptionSet((prevOptions) => [...prevOptions, ""]);
    } else if (type === "second") {
      setSecondOptionSet((prevOptions) => [...prevOptions, ""]);
    }
  };

  //---Option Deletion

  const handleDeleteMatchOption = (index, type) => {
    const minOptionSetLength = firstOptionSet.length;

    if (
      (type === "first" && minOptionSetLength > 2) ||
      (type === "second" && secondOptionSet.length > minOptionSetLength)
    ) {
      if (type === "first") {
        setFirstOptionSet((prevOptions) =>
          prevOptions.filter((_, i) => i !== index)
        );
      } else if (type === "second") {
        setSecondOptionSet((prevOptions) =>
          prevOptions.filter((_, i) => i !== index).slice(0, minOptionSetLength)
        );
      }
    }
  };

  //----Answer Add

  const handleAddMatchAnswer = () => {
    if (matchAnswerValues.length < firstOptionSet.length) {
      setMatchAnswerValues((prevOptions) => [...prevOptions, ""]);
    } else {
      console.log("Maximum number of answers reached");
    }
  };
  //---Answer Deletion

  const handleDeleteMatchAnswer = (index) => {
    const minAnswerLength = firstOptionSet.length;

    if (matchAnswerValues.length > minAnswerLength) {
      setMatchAnswerValues((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //---Assessment Data Creation

  const handleMatchQuestionCreation = () => {
    const assessmentData = {
      left: firstOptionSet,
      question: questionValue,
      type: questionType,
      right: secondOptionSet,
      options: [],
      answer: matchAnswerValues,
      id: questionId,
    };
    console.log(assessmentData);
    // if (examTime.trim() === "") {
    //   setErrorValue("Time is missing");
    //   setValidation(true);
    // } else
    if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      questionEdit(assessmentData);
    }
  };

  //dictation
  const handleDictaionQuestionChange = (html) => {
    setQuestionValue(html);
  };

  const handleAddRecording = () => {
    setRecordings([
      ...recordings,
      { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
    ]);
  };

  const handleDeleteRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings.splice(index, 1);
    setRecordings(updatedRecordings);
  };

  const handleAnswerChange = (index, answer) => {
    const updatedRecordings = [...recordings];
    updatedRecordings[index].answer = answer;
    setRecordings(updatedRecordings);
  };

  const toggleRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings[index].isRecording = !recordings[index].isRecording;
    setRecordings(updatedRecordings);
  };

  const handleStartStopRecording = async (index) => {
    const isRecording = recordings[index].isRecording;
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(stream);
        const chunks = [];

        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        mediaRecorder.onstop = async () => {
          const blob = new Blob(chunks, { type: "audio/wav" });

          // Send the audio blob to the backend
          const formData = new FormData();
          formData.append("audio", blob);
          try {
            const response = await fetch(`${baseUrl}/voice/upload/new`, {
              method: "POST",
              body: formData,
            });
            if (!response.ok) {
              throw new Error("Failed to upload audio");
            }
            const responseData = await response.json();
            const audioUrl = responseData.data[0].url;

            // Update the recordings state with the received audio URL
            const updatedRecordings = [...recordings];
            updatedRecordings[index].audio = audioUrl;
            updatedRecordings[index].mediaRecorder = null;
            setRecordings(updatedRecordings);
          } catch (error) {
            console.error("Error uploading audio:", error);
          }
        };

        mediaRecorder.start();
        toggleRecording(index);
        const updatedRecordings = [...recordings];
        updatedRecordings[index].mediaRecorder = mediaRecorder;
        setRecordings(updatedRecordings);
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    } else {
      const mediaRecorder = recordings[index].mediaRecorder;
      mediaRecorder.stop();
      toggleRecording(index);
    }
  };

  const handleDictationQuestionClear = () => {
    setRecordings([
      { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
    ]);
    setQuestionValue("");
  };

  const handleDictationQuestionCreation = () => {
    const asessmentData = {
      left: recordings.map((recording) => recording.audio),
      question: questionValue,
      type: questionType,
      right: [],
      options: [],
      answer: recordings.map((recording) => recording.answer),
      id: questionId,
    };
    console.log(asessmentData);
    // if (examTime.trim() === "") {
    //   setErrorValue("Time is missing");
    //   setValidation(true);
    // } else
    if (recordings.some((recording) => !recording.audio)) {
      setErrorValue("Audio is missing");
      setValidation(true);
    } else if (recordings.some((recording) => !recording.answer)) {
      setErrorValue("Answer is missing");
      setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      questionEdit(asessmentData);
    }
  };
  //match the following with image

  //Match the following question handling sections{

  const handleImageMatchQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----First Option Set handling

  const handleImageFirstOptionSet = async (index, file) => {
    if (typeof file === "string") {
      setFirstOptionSetImage((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[index] = file;
        return updatedImages;
      });
    } else {
      const formData = new FormData();
      formData.append("audio", file);

      try {
        const response = await fetch(`${baseUrl}/voice/upload/new`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json(); // Assuming the API returns the URL in JSON format
          const imageUrl = responseData.data[0].url;
          setFirstOptionSetImage((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = imageUrl;
            return updatedImages;
          });
        } else {
          // Handle error response from the API
          console.error("Failed to upload image", response);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleImageReplace = (index) => {
    const input = document.getElementById(`fileInput-${index}`);
    input.click();
  };

  const handleReplaceImage = (index) => {
    // Trigger the file input click event
    // document.getElementById(`fileInput_${index}`).click();
    setSelectedImageOptionIndex(index);
    setMediaPopup(true);
    filesGet();
  };
  //-----Second Option Set handling

  const handleImageSecondOptionSet = (index, value) => {
    setSecondOptionSetImageText((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Answer set handling

  const handleImageMatchAnswerKey = (index, value) => {
    setImageMatchAnswerValues((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Options Add

  const handleImageMatchOptionAdd = (type) => {
    if (type === "first") {
      setFirstOptionSetImage((prevOptions) => [...prevOptions, ""]);
    } else if (type === "second") {
      setSecondOptionSetImageText((prevOptions) => [...prevOptions, ""]);
    }
  };

  //---Option Deletion

  const handleDeleteImageMatchOption = (index, type) => {
    const minOptionSetLength = firstOptionSetImage.length;

    if (
      (type === "first" && minOptionSetLength > 2) ||
      (type === "second" &&
        secondOptionSetImageText.length > minOptionSetLength)
    ) {
      if (type === "first") {
        setFirstOptionSetImage((prevOptions) =>
          prevOptions.filter((_, i) => i !== index)
        );
      } else if (type === "second") {
        setSecondOptionSetImageText((prevOptions) =>
          prevOptions.filter((_, i) => i !== index).slice(0, minOptionSetLength)
        );
      }
    }
  };

  //----Answer Add

  const handleAddImageMatchAnswer = () => {
    if (imageMatchAnswerValues.length < firstOptionSetImage.length) {
      setImageMatchAnswerValues((prevOptions) => [...prevOptions, ""]);
    } else {
      console.log("Maximum number of answers reached");
    }
  };

  //---Answer Deletion

  const handleDeleteImageMatchAnswer = (index) => {
    const minAnswerLength = firstOptionSetImage.length;

    if (imageMatchAnswerValues.length > minAnswerLength) {
      setImageMatchAnswerValues((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //clear section

  const handlematchImageQuestionClear = () => {
    setFirstOptionSetImage(["", "", "", ""]);
    setSecondOptionSetImageText(["", "", "", ""]);
    setImageMatchAnswerValues(["", "", "", ""]);
    setQuestionValue("");
  };

  //---Assessment Data Creation

  const handleImageMatchQuestionCreation = () => {
    const asessmentData = {
      left: firstOptionSetImage,
      question: questionValue,
      type: questionType,
      right: secondOptionSetImageText,
      options: [],
      answer: imageMatchAnswerValues,
      id: questionId,
    };

    console.log(asessmentData);
    if (questionValue.trim() === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (firstOptionSetImage.every((option) => option.trim() === "")) {
      setErrorValue("First option set is missing");
      setValidation(true);
    } else if (
      secondOptionSetImageText.every((option) => option.trim() === "")
    ) {
      setErrorValue("Second option set is missing");
      setValidation(true);
    } else if (imageMatchAnswerValues.every((answer) => answer.trim() === "")) {
      setErrorValue("Answer list is missing");
      setValidation(true);
      // } else if (examTime.trim() === "") {
      //   setErrorValue("Time is missing");
      //   setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      questionEdit(asessmentData);
    }
  };

  //crossword puzzle
  //Question Handling

  const handleCrossWordPuzzleQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----Answer Handling

  const handleCrossWordPuzzleinputChange = (e, row, col) => {
    const newGrid = [...grid];
    newGrid[row][col] = {
      ...newGrid[row][col], // Preserve existing properties
      cluevalue: e.target.value, // Update the cell value
    };
    setGrid(newGrid);
  };

  //clear section

  const handleCrossWordPuzzleQuestionClear = () => {
    setQuestionValue("");
    setOptions(["", "", "", ""]);
    setCorrectAnswers([]);
  };

  const generateUniqueId = () => {
    const timestamp = new Date().getTime(); // Get current timestamp
    const randomId = Math.random().toString(36).substring(2, 15); // Generate random number
    return `${timestamp}-${randomId}`; // Combine timestamp and random number
  };

  const addClue = (direction) => {
    const newNumber =
      direction === "across" ? cluesAcross.length + 1 : cluesDown.length + 1;
    const newId = generateUniqueId(); // Generate a unique ID for the clue
    const newClue = { id: newId, number: newNumber, clue: "", answer: "" };
    if (direction === "across") {
      setCluesAcross([...cluesAcross, newClue]);
    } else if (direction === "down") {
      setCluesDown([...cluesDown, newClue]);
    }
  };

  const handleClueChange = (e, index, direction) => {
    const updatedClues =
      direction === "across" ? [...cluesAcross] : [...cluesDown];
    updatedClues[index].clue = e.target.value;
    if (direction === "across") {
      setCluesAcross(updatedClues);
    } else if (direction === "down") {
      setCluesDown(updatedClues);
    }
  };

  // Function to handle click on the button to start selecting a cell for the clue
  const startSelectingCell = (index, direction, id) => {
    const clues = direction === "across" ? cluesAcross : cluesDown;
    const clueObj = clues[index];

    // Check if the clue and its answer are not empty
    if (!clueObj.clue || !clueObj.answer) {
      // Show error message and prevent selecting the starting cell
      setErrorValue(
        "Please enter both clue and answer before selecting the starting cell."
      );
      setValidation(true);
      return;
    }

    setSelectingCell(true);
    setSelectedClueIndex(index);
    setSelectedDirection(direction);
  };

  // Function to handle click on the crossword grid cells
  const handleCellClick = (rowIndex, colIndex) => {
    const prevClueId =
      selectedDirection === "across"
        ? cluesAcross[selectedClueIndex]?.id
        : cluesDown[selectedClueIndex]?.id;
    const prevClueNumber =
      selectedDirection === "across"
        ? cluesAcross[selectedClueIndex]?.number
        : cluesDown[selectedClueIndex]?.number;

    // Clear the cells associated with the previous selection
    if (prevClueId && prevClueNumber && selectedDirection) {
      const updatedGrid = [...grid];
      updatedGrid.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          if (cell.clueID === prevClueId) {
            updatedGrid[rowIndex][colIndex] = {
              cluevalue: "",
              clueNumber: null,
              clueID: "",
            };
          }
        });
      });
      setGrid(updatedGrid);
    }
    if (
      selectingCell &&
      selectedClueIndex !== null &&
      selectedDirection !== null
    ) {
      const updatedGrid = [...grid];
      const clueObj =
        selectedDirection === "across"
          ? cluesAcross[selectedClueIndex]
          : cluesDown[selectedClueIndex];
      const answerLength = clueObj.answer.length;
      let remainingCells;

      // Calculate the remaining cells based on the direction
      if (selectedDirection === "across") {
        remainingCells = updatedGrid[0].length - rowIndex - 1; // Calculate remaining cells to the right
      } else {
        remainingCells = updatedGrid[0].length - colIndex - 1; // Calculate remaining cells below
      }

      // Check if there's enough space to display the entire answer
      if (answerLength > remainingCells) {
        // Set validation state and error message
        setValidation(true);
        setErrorValue("Not enough space to display the entire answer");
        return; // Exit the function
      }

      // Update the grid to mark the starting cell for the clue's answer
      if (selectedDirection === "across") {
        for (let i = 0; i < answerLength; i++) {
          if (rowIndex + i < updatedGrid.length) {
            updatedGrid[rowIndex + i][colIndex] = {
              cluevalue: clueObj.answer[i].toUpperCase(),
              clueNumber: i === 0 ? clueObj.number : null, // Show clue number only in the starting cell
              clueID: clueObj.id || "",
            };
          }
        }
      } else {
        for (let i = 0; i < answerLength; i++) {
          if (colIndex + i < updatedGrid[rowIndex].length) {
            updatedGrid[rowIndex][colIndex + i] = {
              cluevalue: clueObj.answer[i].toUpperCase(),
              clueNumber: i === 0 ? clueObj.number : null, // Show clue number only in the starting cell
              clueID: clueObj.id || "",
            };
          }
        }
      }

      // Update the state with the modified grid and reset the selection flags
      setGrid(updatedGrid);
      setSelectingCell(false);
      setSelectedClueIndex(null);
      setSelectedDirection(null);
    }
  };

  const handleClueAnswerChange = (e, index, direction) => {
    const updatedClues =
      direction === "across" ? [...cluesAcross] : [...cluesDown];
    const newAnswer = e.target.value;

    // Update the clue's answer
    updatedClues[index].answer = newAnswer;
    if (direction === "across") {
      setCluesAcross(updatedClues);
    } else if (direction === "down") {
      setCluesDown(updatedClues);
    }

    // Update the grid cells corresponding to the clue's answer
    const updatedGrid = [...grid];
    const clueObj =
      direction === "across" ? cluesAcross[index] : cluesDown[index];
    const answerLength = newAnswer.length;

    // Find the starting cell for the clue's answer
    updatedGrid.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (
          (direction === "across" &&
            cell.clueNumber === clueObj.number &&
            cell.clueID === clueObj.id) ||
          (direction === "down" &&
            cell.clueNumber === clueObj.number &&
            cell.clueID === clueObj.id &&
            rowIndex >= rowIndex)
        ) {
          // Clear the cells affected by the removal
          for (let i = 0; i < row.length; i++) {
            const targetRowIndex =
              direction === "down" ? rowIndex : rowIndex + i;
            const targetColIndex =
              direction === "across" ? colIndex : colIndex + i;
            if (
              updatedGrid[targetRowIndex] &&
              updatedGrid[targetRowIndex][targetColIndex] &&
              (direction === "across"
                ? i >= answerLength
                : i >= answerLength && rowIndex + i >= rowIndex)
            ) {
              updatedGrid[targetRowIndex][targetColIndex].cluevalue = "";
            }
          }

          // Update the remaining cells with the adjusted answer
          for (let i = 0; i < answerLength; i++) {
            const targetRowIndex =
              direction === "down" ? rowIndex : rowIndex + i;
            const targetColIndex =
              direction === "across" ? colIndex : colIndex + i;
            if (
              updatedGrid[targetRowIndex] &&
              updatedGrid[targetRowIndex][targetColIndex]
            ) {
              updatedGrid[targetRowIndex][targetColIndex].cluevalue = newAnswer[
                i
              ].toUpperCase();
            }
          }
        }
      });
    });

    // Update the grid state with the modified grid
    setGrid(updatedGrid);
  };

  //---Assessment Data Creation

  const handleCrossWordPuzzleQuestionCreation = () => {
    const asessmentData = {
      question: questionValue,
      type: questionType,
      options: grid,
      answer: "",
      left: cluesAcross,
      right: cluesDown,
      id: questionId,
    };
    console.log(asessmentData);
    const hasClue = grid.some((row) =>
      row.some((cell) => cell.clueNumber !== null || cell.clueID !== "")
    );
    if (questionValue === "") {
      setErrorValue("Question is missing");
      setValidation(true);
    } else if (
      cluesAcross.every((clue) => clue.clue === "") &&
      cluesDown.every((clue) => clue.clue === "")
    ) {
      setErrorValue(
        "Please enter at least one clue for either across or down direction."
      );
      setValidation(true);
      return;
    } else if (!hasClue) {
      setErrorValue("Please assign at least one answer to a cell.");
      setValidation(true);
      return;
      // } else if (examTime.trim() === "") {
      //   setErrorValue("Time is missing");
      //   setValidation(true);
    } else if (correctMark.trim() === "") {
      setErrorValue("Mark is missing");
      setValidation(true);
    } else if (inCorrectMark.trim() === "") {
      setErrorValue("Incorrect mark is missing");
      setValidation(true);
    } else {
      questionEdit(asessmentData);
    }
  };

  //}

  async function topicSort(id, name) {
    const topic_id_main = localStorage.getItem("SelectedTopicID");
    const topic_id_selected = localStorage.getItem("SelectedsubTopicID");
    const topic_name_selected = localStorage.getItem("SelectedsubTopicName");
    await fetch(`${baseUrl}/topics/pop`, {
      method: "POST",
      body: JSON.stringify({
        main_topic_id: topic_id_main,
        topic_id: topic_id_selected,
        topic_name: topic_name_selected,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const topicOption = data.data[0].new_topics.map((topic) => ({
          id: topic.id,
          value: topic.value,
        }));
        console.log(topicOption);
        setTopics(topicOption);
        setSingleTopicId(data.data[0].req_topicid);
        console.log(data.data[0].req_topicid);
        setSingleTopicName(data.data[0].req_topicname);
        console.log(data.data[0].req_topicname);
      });
  }

  async function adminGenaration() {
    const topic_id_main = localStorage.getItem("SelectedTopicID");
    const topic_id_selected = localStorage.getItem("SelectedsubTopicID");
    const topic_name_selected = localStorage.getItem("SelectedsubTopicName");
    await fetch(`${baseUrl}/admin/lesson/generation`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: topic_id_selected,
        topic_name: topic_name_selected,
        main_topic_id: topic_id_main,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAdminId(data.data[0].admin_id);
        setQuestions(data.data[0].assessments);
        setLoading(false);
      });
  }
  async function questionEdit(data) {
   const topic_id_main = localStorage.getItem("SelectedTopicID");
    const topic_id_selected = localStorage.getItem("SelectedsubTopicID");
    const topic_name_selected = localStorage.getItem("SelectedsubTopicName");
    await fetch(`${baseUrl}/assessment/edit`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: topic_id_selected,
        topic_name: topic_name_selected,
        main_topic_id: topic_id_main,
        admin_id: adminId,
        assessment: data,
        comment_by_other: isCheckedOther || false,
        comment_by_mentor: isCheckedMentor || false,
        comment_by_learner: isCheckedLearner || false,
        mark: correctMark || "",
        // time: examTime || "",
        negative_mark: inCorrectMark || "",
        publish_status: assessmentStatus,
        question_explained: explanation,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          adminGenaration();
          setLoading(true);
          setQuestionCreationSuccess(true);
        }
      });
  }

  async function questionDelete() {
    const topic_id_main = localStorage.getItem("SelectedTopicID");
    const topic_id_selected = localStorage.getItem("SelectedsubTopicID");
    await fetch(`${baseUrl}/assessments/delete/new`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: topic_id_selected,
        main_topic_id: topic_id_main,
        admin_id: adminId,
        unique_id: uniqueId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          adminGenaration();
          setLoading(true);
          setSelectedQuestionIndex(0);
          setSelectedNumber(0);
          setInitiallySelectedQuestion(1);
        }
      });
  }

  async function filesGet() {
    await fetch(`${baseUrl}/gallery/file/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMediaCollection(data.data);
      });
  }

  const numbers = Array.from(
    { length: questions.length },
    (_, index) => index + 1
  );

  useEffect(() => {
    topicSort(state.topicId, state.topicName);
  }, [orgId]);

  useEffect(() => {
    if (singleTopicId && singleTopicName) {
      adminGenaration();
      setMainLoading(false);
    }
    console.log(singleTopicName);
  }, [singleTopicId, singleTopicName]);

  useEffect(() => {
    if (questions.length > 0) {
      setCurrentQuestion(questions[selectedQuestionIndex]);

      console.log(questions);
    }
    console.log(questions);
    console.log(selectedQuestionIndex);
    console.log(selectedNumber);
    console.log(initiallySelectedQuestion);

    console.log(questions[selectedQuestionIndex]);
  }, [questions, selectedQuestionIndex]);

  useEffect(() => {
    if (currentQuestion.type === "fill in the blanks") {
      setContent(currentQuestion.question);

      // Extract blank names from the question
      const blankNames = currentQuestion.question.match(/\[blank \d+\]/g) || [];

      // Initialize blank answers with values from the currentQuestion.answer object
      const initialBlankAnswers = {};
      blankNames.forEach((blankName) => {
        const numericKey = blankName.match(/\d+/);
        if (numericKey) {
          // Use the numericKey to find the corresponding answer key in the API response
          const apiAnswerKey = numericKey[0];
          initialBlankAnswers[blankName] =
            currentQuestion.answer[apiAnswerKey] || "";
        }
      });

      // Set the state with the initial values
      setBlankAnswers(initialBlankAnswers);
      setLoading(false);

      setQuestionType(currentQuestion.type);
      setQuestionId(currentQuestion.id);
      setUniqueId(currentQuestion.unique_id);
      setCorrectMark(currentQuestion.mark);
      // setExamTime(currentQuestion.time);
      setInCorrectMark(currentQuestion.negative_mark);
      setIsCheckedLearner(currentQuestion.comment_by_learner);
      setIsCheckedMentor(currentQuestion.comment_by_mentor);
      setIsCheckedOther(currentQuestion.comment_by_other);
      setAssessmentStatus(currentQuestion.publish_status);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setCrossWordPuzzle(false);
      setDictationView(false);
      setFillTheBlankView(true);
      setMatchOptionImageView(false);
      setUniqueId(currentQuestion.unique_id);
      setExplanation(currentQuestion.question_explained);
      console.log(currentQuestion.question);
    } else if (
      currentQuestion.type === "multiple choices" ||
      currentQuestion.type === "multiple choice"
    ) {
      if (currentQuestion.generation === "admin") {
        setLoading(false);
        setFillTheBlankView(false);
        setOrdinary(false);
        setMatchValueView(false);
        setDictationView(false);
        setMultipleChoiceView(true);
        setCrossWordPuzzle(false);
        setMatchOptionImageView(false);
        setOptions(currentQuestion.options);
        setQuestionValue(currentQuestion.question);
        setQuestionType(currentQuestion.type);
        setQuestionId(currentQuestion.id);
        setUniqueId(currentQuestion.unique_id);
        setCorrectMark(currentQuestion.mark);
        // setExamTime(currentQuestion.time);
        setInCorrectMark(currentQuestion.negative_mark);
        setIsCheckedLearner(currentQuestion.comment_by_learner);
        setIsCheckedMentor(currentQuestion.comment_by_mentor);
        setIsCheckedOther(currentQuestion.comment_by_other);
        setAssessmentStatus(currentQuestion.publish_status);
        setExplanation(currentQuestion.question_explained);
        if (currentQuestion.answer !== "") {
          setCorrectAnswers(
            currentQuestion.answer.map((answer) => {
              return currentQuestion.options.findIndex(
                (option) => option === answer
              );
            })
          );
        }
      } else {
        setLoading(false);
        setFillTheBlankView(false);
        setOrdinary(false);
        setMatchValueView(false);
        setDictationView(false);
        setCrossWordPuzzle(false);
        setMultipleChoiceView(true);
        setMatchOptionImageView(false);
        setOptions(currentQuestion.options);
        setQuestionValue(currentQuestion.question);
        setQuestionType(currentQuestion.type);
        setQuestionId(currentQuestion.id);
        setUniqueId(currentQuestion.unique_id);
        setExplanation(currentQuestion.question_explained);
      }
    } else if (currentQuestion.type === "ordinary") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setMatchValueView(false);
      setDictationView(false);
      setOrdinary(true);
      setCrossWordPuzzle(false);
      setMatchOptionImageView(false);
      setQuestionValue(currentQuestion.question);
      setQuestionType(currentQuestion.type);
      setQuestionId(currentQuestion.id);
      setOrdinaryAnswer(currentQuestion.answer);
      setUniqueId(currentQuestion.unique_id);
      setCorrectMark(currentQuestion.mark);
      // setExamTime(currentQuestion.time);
      setInCorrectMark(currentQuestion.negative_mark);
      setIsCheckedLearner(currentQuestion.comment_by_learner);
      setIsCheckedMentor(currentQuestion.comment_by_mentor);
      setIsCheckedOther(currentQuestion.comment_by_other);
      setAssessmentStatus(currentQuestion.publish_status);
      setExplanation(currentQuestion.question_explained);
    } else if (currentQuestion.type === "Match the following") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(true);
      setDictationView(false);
      setCrossWordPuzzle(false);
      setMatchOptionImageView(false);
      setQuestionValue(currentQuestion.question);
      setFirstOptionSet(currentQuestion.left);
      setSecondOptionSet(currentQuestion.right);
      setMatchAnswerValues(currentQuestion.answer);
      setQuestionType(currentQuestion.type);
      setQuestionId(currentQuestion.id);
      setUniqueId(currentQuestion.unique_id);
      setCorrectMark(currentQuestion.mark);
      // setExamTime(currentQuestion.time);
      setInCorrectMark(currentQuestion.negative_mark);
      setIsCheckedLearner(currentQuestion.comment_by_learner);
      setIsCheckedMentor(currentQuestion.comment_by_mentor);
      setIsCheckedOther(currentQuestion.comment_by_other);
      setAssessmentStatus(currentQuestion.publish_status);
      setExplanation(currentQuestion.question_explained);
    } else if (currentQuestion.type === "dictation") {
      const { answer, left } = currentQuestion;

      // Create an array of recordings with initial values from API response
      const initialRecordings = left.map((audio, index) => ({
        audio,
        answer: answer[index] || "", // Use empty string if answer is not provided in API response
        mediaRecorder: null,
        isRecording: false,
      }));

      // Set the recordings state with the initial values
      setRecordings(initialRecordings);
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setDictationView(true);
      setCrossWordPuzzle(false);
      setMatchOptionImageView(false);
      setQuestionValue(currentQuestion.question);
      setQuestionType(currentQuestion.type);
      setQuestionId(currentQuestion.id);
      setUniqueId(currentQuestion.unique_id);
      setCorrectMark(currentQuestion.mark);
      // setExamTime(currentQuestion.time);
      setInCorrectMark(currentQuestion.negative_mark);
      setIsCheckedLearner(currentQuestion.comment_by_learner);
      setIsCheckedMentor(currentQuestion.comment_by_mentor);
      setIsCheckedOther(currentQuestion.comment_by_other);
      setAssessmentStatus(currentQuestion.publish_status);
      setExplanation(currentQuestion.question_explained);
    } else if (currentQuestion.type === "Match the following with image") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setDictationView(false);
      setCrossWordPuzzle(false);
      setMatchOptionImageView(true);
      setQuestionValue(currentQuestion.question);
      setFirstOptionSetImage(currentQuestion.left);
      setSecondOptionSetImageText(currentQuestion.right);
      setImageMatchAnswerValues(currentQuestion.answer);
      setQuestionType(currentQuestion.type);
      setQuestionId(currentQuestion.id);
      setUniqueId(currentQuestion.unique_id);
      setCorrectMark(currentQuestion.mark);
      // setExamTime(currentQuestion.time);
      setInCorrectMark(currentQuestion.negative_mark);
      setIsCheckedLearner(currentQuestion.comment_by_learner);
      setIsCheckedMentor(currentQuestion.comment_by_mentor);
      setIsCheckedOther(currentQuestion.comment_by_other);
      setAssessmentStatus(currentQuestion.publish_status);
      setExplanation(currentQuestion.question_explained);
    } else if (currentQuestion.type === "cross word puzzle") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setDictationView(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(true);
      setQuestionValue(currentQuestion.question);
      setCluesAcross(currentQuestion.left);
      setCluesDown(currentQuestion.right);
      setGrid(currentQuestion.options);
      setMatchAnswerValues(currentQuestion.answer);
      setQuestionType(currentQuestion.type);
      setQuestionId(currentQuestion.id);
      setUniqueId(currentQuestion.unique_id);
      setCorrectMark(currentQuestion.mark);
      // setExamTime(currentQuestion.time);
      setInCorrectMark(currentQuestion.negative_mark);
      setIsCheckedLearner(currentQuestion.comment_by_learner);
      setIsCheckedMentor(currentQuestion.comment_by_mentor);
      setIsCheckedOther(currentQuestion.comment_by_other);
      setAssessmentStatus(currentQuestion.publish_status);
      setExplanation(currentQuestion.question_explained);
    }
    console.log(currentQuestion);
  }, [currentQuestion]);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={questionCreationSuccess}
        autoHideDuration={3000}
        onClose={() => {
          setQuestionCreationSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {"Question updated successfully"}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validation}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorValue}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={settingsValidation}
        sx={{ width: "18rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setSettingsValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="info"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {"Settings added please click update button"}
        </Alert>
      </Snackbar>
      {mainLoading ? (
        <Loader />
      ) : (
        <>
          <div className={style.Header}>
            <div className={style.navLinks}>
              <div className={style.linkLeft}>
                <p
                  onClick={() => {
                    navigate("/learning");
                  }}
                >
                  {mainCategoryPath}
                </p>
              </div>
              <span>/</span>
              {subcategoryPath.map((category, index) => {
                const truncatedName =
                  category.Name.length > 10
                    ? category.Name.substring(0, 10) + "..."
                    : category.Name;
                return (
                  <div className={style.linkRight} key={category.category_id}>
                    {index > 0 ? <span>/</span> : ""}
                    <p
                      onClick={() => {
                        navigate("/learning");
                      }}
                    >
                      {truncatedName}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className={style.topicSelection}>
              <Autocomplete
                options={topics}
                getOptionLabel={(option) => option.value}
                sx={{ width: "65%" }}
                id="disable-clearable"
                disableClearable
                value={{ id: singleTopicId, value: singleTopicName }} // Set default value here
                renderInput={(params) => <TextField {...params} label="" />}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontSize: "1.25rem" }}>
                    {option.value}
                  </li>
                )}
                onChange={(event, newValue) => {
                  console.log(newValue.id, newValue.value);
                  // topicSort(newValue.id, newValue.value);
                  setSelectedQuestionIndex(0);
                  setMultipleChoiceView(false);
                  setOrdinary(false);
                  localStorage.setItem("SelectedsubTopicID",newValue.id);
                  localStorage.setItem("SelectedsubTopicName",newValue.value);
                  topicSort()
                  setMatchValueView(false);
                  setFillTheBlankView(false);
                  setQuestions([]);
                  setMainLoading(true);
                }}
              />
            </div>
            <div className={style.headerButtons}>
              <div className={style.assessmentCreation}>
                <Tooltip title="Assessment Create">
                  <IconButton>
                    <IoCreateSharp
                      onClick={() => {
                        navigate("/asessments/creation", {
                          state: {
                            // topic_Id: singleTopicId,
                            // topic_Name: singleTopicName,
                            // main_TopicId: mainTopicId,
                            org_Id: orgId,
                            admin_Id: adminId,
                            subCategories: subcategoryPath,
                            mainCategory: state.mainCategory,
                          },
                        });
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={style.viewChapter}>
                <Tooltip title="Chapter View">
                  <IconButton>
                    <PiListPlusFill
                      onClick={() => {
                        navigate("/lesson/create/admin", {
                          state: {
                            organization: orgId,
                            // mainTopic: mainTopicId,
                            // topicName: singleTopicName,
                            // topicId: singleTopicId,
                            subcategories: subcategoryPath,
                            mainCategory: mainCategoryPath,
                            // topics:topicList
                          },
                        });
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>

          {questions.length === 0 ? (
            <div className={style.noSelection}>
              <Alert severity="info">Please create an assessment</Alert>
            </div>
          ) : (
            <div className={style.asessmentSection}>
              <div className={style.sectionLeft}>
                {loading ? (
                  <div className={style.loader}>
                    <SyncLoader
                      color="#36D7B7"
                      size={15}
                      margin={5}
                      loading={true}
                    />
                  </div>
                ) : (
                  <>
                    {fillTheBlankView ? (
                      <div className={style.fillQuestionSection}>
                        <div className={style.questionCreation}>
                          <div className={style.creationTop}>
                            <div className={style.creationTopLeft}>
                              <TbMessageQuestion />
                              <p>Enter your question?</p>
                              <span>*</span>
                            </div>
                            <div className={style.actionButton}>
                              <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={style.creationTopRight}>
                                <Button
                                  variant="contained"
                                  endIcon={<MdAdd />}
                                  color="secondary"
                                  onClick={handleAddBlankClick}
                                >
                                  Add Blank
                                </Button>
                              </div>

                              <div className={style.settingsIcon}>
                                <IconButton>
                                  <IoSettingsOutline
                                    onClick={() => {
                                      setSettingView(true);
                                    }}
                                  />
                                </IconButton>
                              </div>

                              <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={
                                        handleFilltheBlankQuestionCreation
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className={style.creationBottomBlank}>
                            <div className={style.formatButtons}>
                              <Button
                                onClick={() => {
                                  handleToggleBold("bold");
                                }}
                                style={{
                                  background: activeButtons.bold
                                    ? "#e6e6e6"
                                    : "transparent",
                                  color: "#2e2e40",
                                  fontSize: "1.5rem",
                                  padding: "0.25rem",
                                  minWidth: " 3rem",
                                  margin: "0 0.25rem",
                                }}
                              >
                                <MdFormatBold />
                              </Button>
                              <Button
                                onClick={() => {
                                  handleToggleItalic("italic");
                                }}
                                style={{
                                  background: activeButtons.italic
                                    ? "#e6e6e6"
                                    : "transparent",
                                  color: "#2e2e40",
                                  fontSize: "1.5rem",
                                  padding: "0.25rem",
                                  minWidth: " 3rem",
                                  margin: "0 0.25rem",
                                }}
                              >
                                <MdFormatItalic />
                              </Button>
                              <Button
                                onClick={() => {
                                  handleToggleStrikeThrough("strikethrough");
                                }}
                                style={{
                                  background: activeButtons.strikethrough
                                    ? "#e6e6e6"
                                    : "transparent",
                                  color: "#2e2e40",
                                  fontSize: "1.5rem",
                                  padding: "0.25rem",
                                  minWidth: " 3rem",
                                  margin: "0 0.25rem",
                                }}
                              >
                                <MdFormatStrikethrough />
                              </Button>
                              <Button
                                onClick={() => {
                                  handleToggleUnderline("underline");
                                }}
                                style={{
                                  background: activeButtons.underline
                                    ? "#e6e6e6"
                                    : "transparent",
                                  color: "#2e2e40",
                                  fontSize: "1.5rem",
                                  padding: "0.25rem",
                                  minWidth: " 3rem",
                                  margin: "0 0.25rem",
                                }}
                              >
                                <TbUnderline />
                              </Button>
                            </div>
                            <div className={style.textArea}>
                              <TextField
                                inputRef={textFieldRef}
                                sx={{ width: "100%" }}
                                multiline
                                rows={12}
                                variant="outlined"
                                onChange={(e) => {
                                  setContent(e.target.value);
                                }}
                                value={content}
                                onKeyDown={handleKeyDown}
                                onBlur={handleBlur}
                                InputProps={{
                                  style: {
                                    fontWeight: bold ? "bold" : "normal",
                                    fontStyle: italic ? "italic" : "normal",
                                    textDecoration: `${
                                      strikeThrough ? "line-through" : ""
                                    } ${underline ? "underline" : ""}`,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={style.optionSet}>
                          <div className={style.optionTop}>
                            <MdOutlineQuestionAnswer />
                            <p>Enter your blank values</p>
                            <p>(To remove the blanks use the delete button)</p>
                            <span>*</span>
                          </div>
                          <div className={style.optionBottomLeft}>
                            {Object.keys(blankAnswers).map((blankName) => {
                              // Extract the numeric key from the blank name

                              return (
                                <div
                                  key={blankName}
                                  className={style.blankValues}
                                >
                                  <p>{blankName}:</p>
                                  <TextField
                                    sx={{ width: "75%", margin: "0 1rem" }}
                                    variant="outlined"
                                    value={blankAnswers[blankName]}
                                    onChange={(e) =>
                                      handleBlankAnswerChange(
                                        blankName,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <IconButton
                                    disabled={
                                      Object.keys(blankAnswers).length <= 1
                                    }
                                    aria-label="delete"
                                    color="error"
                                    onClick={() => handleDeleteBlank(blankName)}
                                  >
                                    <MdDelete />
                                  </IconButton>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className={style.explanation}>
                          <h4>Explanation</h4>
                          <div className={style.explanationTextArea}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={explanation}
                              onChange={handleExplanationChange}
                              style={{ width: "100%", height: "200px" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {multipleChoiceView ? (
                      <div className={style.fillQuestionSection}>
                        <div className={style.questionCreation}>
                          <div className={style.creationTop}>
                            <div className={style.creationTopLeft}>
                              <TbMessageQuestion />
                              <p>Enter your question?</p>
                              <span>*</span>
                            </div>
                            <div className={style.actionButton}>
                              <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={style.settingsIcon}>
                                <IconButton>
                                  <IoSettingsOutline
                                    onClick={() => {
                                      setSettingView(true);
                                    }}
                                  />
                                </IconButton>
                              </div>
                              <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={handleMulitipleQuestionCreation}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className={style.creationBottom}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={questionValue}
                              onChange={handleMultipleQuestionChange}
                              style={{ width: "100%", height: "300px" }}
                            />
                            {/* <TextField
                              sx={{ width: "100%" }}
                              id="outlined-basic"
                              multiline
                              rows={6}
                              variant="outlined"
                              onChange={(e) => {
                                setQuestionValue(e.target.value);
                              }}
                              value={questionValue}
                            /> */}
                          </div>
                        </div>

                        <div className={style.optionSet}>
                          <div className={style.optionTop}>
                            <MdOutlineQuestionAnswer />
                            <p>Enter your options</p>
                            <p>
                              (Please check the box against the correct answer )
                            </p>
                            <span>*</span>
                          </div>
                          <div className={style.optionBottom}>
                            <div className={style.optionBottomLeft}>
                              {options.map((option, index) => (
                                <div key={index} className={style.optionItem}>
                                  <TextField
                                    sx={{ width: "95%" }}
                                    required
                                    variant="filled"
                                    value={option}
                                    onChange={(e) =>
                                      handleMultipleAnsweChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Checkbox
                                    checked={correctAnswers.includes(index)}
                                    onChange={() => handleCheckboxChange(index)}
                                    disabled={options[index] === ""}
                                  />
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    onClick={() =>
                                      handleMultipleOptionDelete(index)
                                    }
                                    disabled={deleteDisable}
                                  >
                                    <MdDelete />
                                  </IconButton>
                                </div>
                              ))}
                            </div>
                            <div className={style.firstOptionAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={handleMultipleOptionAdd}
                              >
                                Add Options
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className={style.explanation}>
                          <h4>Explanation</h4>
                          <div className={style.explanationTextArea}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={explanation}
                              onChange={handleExplanationChange}
                              style={{ width: "100%", height: "200px" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {ordinary ? (
                      <div className={style.fillQuestionSection}>
                        <div className={style.questionCreation}>
                          <div className={style.creationTop}>
                            <div className={style.creationTopLeft}>
                              <TbMessageQuestion />
                              <p>Enter your question?</p>
                              <span>*</span>
                            </div>
                            <div className={style.actionButton}>
                              <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={style.settingsIcon}>
                                <IconButton>
                                  <IoSettingsOutline
                                    onClick={() => {
                                      setSettingView(true);
                                    }}
                                  />
                                </IconButton>
                              </div>
                              <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={handleOrdinaryQuestionCreation}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className={style.creationBottom}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={questionValue}
                              onChange={handleOrdinaryQuestionChange}
                              style={{ width: "100%", height: "300px" }}
                            />
                            {/* <TextField
                              sx={{ width: "100%" }}
                              id="outlined-basic"
                              multiline
                              rows={6}
                              variant="outlined"
                              onChange={(e) => {
                                setQuestionValue(e.target.value);
                              }}
                              value={questionValue}
                            /> */}
                          </div>
                        </div>
                        <div className={style.optionSet}>
                          <div className={style.optionTop}>
                            <MdOutlineQuestionAnswer />
                            <p>Enter your answer</p>
                            <span>*</span>
                          </div>
                          <div className={style.optionBottomLeft}>
                            <TextField
                              fullWidth
                              multiline
                              rows={8}
                              variant="outlined"
                              value={ordinaryAnswer}
                              onChange={(e) => {
                                // Append the entered answer to the array
                                setOrdinaryAnswer(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className={style.explanation}>
                          <h4>Explanation</h4>
                          <div className={style.explanationTextArea}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={explanation}
                              onChange={handleExplanationChange}
                              style={{ width: "100%", height: "200px" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {matchValueView ? (
                      <div className={style.fillQuestionSection}>
                        <div className={style.questionCreation}>
                          <div className={style.creationTop}>
                            <div className={style.creationTopLeft}>
                              <TbMessageQuestion />
                              <p>Enter your question?</p>
                              <span>*</span>
                            </div>

                            <div className={style.actionButton}>
                              <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={style.settingsIcon}>
                                <IconButton>
                                  <IoSettingsOutline
                                    onClick={() => {
                                      setSettingView(true);
                                    }}
                                  />
                                </IconButton>
                              </div>

                              <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={handleMatchQuestionCreation}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>

                          <div className={style.creationBottom}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={questionValue}
                              onChange={handleMatchQuestionChange}
                              style={{ width: "100%", height: "300px" }}
                            />
                            {/* <TextField
                              sx={{ width: "100%" }}
                              id="outlined-basic"
                              multiline
                              rows={5}
                              variant="outlined"
                              onChange={(e) => {
                                setQuestionValue(e.target.value);
                              }}
                              value={questionValue}
                            /> */}
                          </div>
                        </div>
                        <div className={style.mainOptionSet}>
                          <div className={style.firstOptionSet}>
                            <div className={style.firstOptionTop}>
                              <MdOutlineQuestionAnswer />
                              <p>Enter your first option set</p>
                            </div>
                            {firstOptionSet.map((first, index) => (
                              <div
                                className={style.firstOptionSetValues}
                                key={index}
                              >
                                <TextField
                                  sx={{ width: "95%" }}
                                  required
                                  variant="filled"
                                  value={first}
                                  onChange={(e) =>
                                    handleFirstOptionSet(index, e.target.value)
                                  }
                                />
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() =>
                                    handleDeleteMatchOption(index, "first")
                                  }
                                  disabled={firstOptionSet.length <= 2}
                                >
                                  <MdDelete />
                                </IconButton>
                              </div>
                            ))}
                            <div className={style.firstOptionAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={() => handleMatchOptionAdd("first")}
                              >
                                Add first Options
                              </Button>
                            </div>
                          </div>
                          <div className={style.secondOptionSet}>
                            <div className={style.secondOptionTop}>
                              <MdOutlineQuestionAnswer />
                              <p>Enter your second option set</p>
                            </div>
                            {secondOptionSet.map((second, index) => (
                              <div
                                className={style.secondOptionSetValues}
                                key={index}
                              >
                                <TextField
                                  sx={{ width: "95%" }}
                                  required
                                  variant="filled"
                                  value={second}
                                  onChange={(e) =>
                                    handleSecondOptionSet(index, e.target.value)
                                  }
                                />
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() =>
                                    handleDeleteMatchOption(index, "second")
                                  }
                                  disabled={secondOptionSet.length <= 2}
                                >
                                  <MdDelete />
                                </IconButton>
                              </div>
                            ))}
                            <div className={style.secondOptionAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={() => handleMatchOptionAdd("second")}
                              >
                                Add second Options
                              </Button>
                            </div>
                          </div>
                          <div className={style.matchAnswerKey}>
                            <div className={style.matchAnswerTop}>
                              <MdOutlineQuestionAnswer />
                              <p>Enter your answer Keys</p>
                            </div>
                            {matchAnswerValues.map((answer, index) => (
                              <div
                                className={style.matchAnswerValues}
                                key={index}
                              >
                                <TextField
                                  sx={{ width: "95%" }}
                                  required
                                  variant="filled"
                                  value={answer}
                                  onChange={(e) =>
                                    handleMatchAnswerKey(index, e.target.value)
                                  }
                                />
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() => handleDeleteMatchAnswer(index)}
                                  disabled={matchAnswerValues.length <= 2}
                                >
                                  <MdDelete />
                                </IconButton>
                              </div>
                            ))}
                            <div className={style.answerKeyAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={handleAddMatchAnswer}
                              >
                                Add Answer
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className={style.explanation}>
                          <h4>Explanation</h4>
                          <div className={style.explanationTextArea}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={explanation}
                              onChange={handleExplanationChange}
                              style={{ width: "100%", height: "200px" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {dictationView ? (
                      <div className={style.fillQuestionSection}>
                        <div className={style.questionCreation}>
                          <div className={style.creationTop}>
                            <div className={style.creationTopLeft}>
                              <TbMessageQuestion />
                              <p>Enter your question?</p>
                              <span>*</span>
                            </div>
                            <div className={style.actionButton}>
                              <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={style.settingsIcon}>
                                <IconButton>
                                  <IoSettingsOutline
                                    onClick={() => {
                                      setSettingView(true);
                                    }}
                                  />
                                </IconButton>
                              </div>

                              <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={handleDictationQuestionCreation}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className={style.creationBottom}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={questionValue}
                              onChange={handleDictaionQuestionChange}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>

                        <div className={style.optionSet}>
                          <div className={style.optionTop}>
                            <MdOutlineQuestionAnswer />
                            <p>Enter your Question</p>
                            <p>(Please enter answer against the audio)</p>
                            <span>*</span>
                          </div>
                          <div className={style.optionBottom}>
                            <div className={style.optionBottomDictation}>
                              {recordings.map((recording, index) => (
                                <div key={index} className={style.MicSection}>
                                  {/* Start Recording button */}
                                  <Button
                                    startIcon={<MdOutlineMic />}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      handleStartStopRecording(index)
                                    }
                                  >
                                    {recording.isRecording
                                      ? "Stop Recording"
                                      : "Start Recording"}
                                  </Button>

                                  <TextField
                                    sx={{
                                      width: "50%",
                                      border: "none",
                                      margin: "0 4px",
                                    }}
                                    label="Enter Answer"
                                    value={recording.answer}
                                    onChange={(e) =>
                                      handleAnswerChange(index, e.target.value)
                                    }
                                  />
                                  {recording.audio && (
                                    <audio controls>
                                      <source
                                        src={recording.audio}
                                        type="audio/wav"
                                      />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                  )}
                                  <Button
                                    disabled={recordings.length <= 1}
                                    sx={{
                                      margin: "0 4px",
                                      fontSize: "1.25rem",
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleDeleteRecording(index)}
                                  >
                                    <MdDelete />
                                  </Button>
                                </div>
                              ))}
                            </div>
                            <div className={style.firstOptionAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={handleAddRecording}
                              >
                                Add Options
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className={style.explanation}>
                          <h4>Explanation</h4>
                          <div className={style.explanationTextArea}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={explanation}
                              onChange={handleExplanationChange}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Match The Following Questions with image */}

                    {matchOptionImageView ? (
                      <div className={style.fillQuestionSection}>
                        <div className={style.questionCreation}>
                          <div className={style.creationTop}>
                            <div className={style.creationTopLeft}>
                              <TbMessageQuestion />
                              <p>Enter your question?</p>
                              <span>*</span>
                            </div>
                            <div className={style.actionButton}>
                              <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={style.settingsIcon}>
                                <IconButton>
                                  <IoSettingsOutline
                                    onClick={() => {
                                      setSettingView(true);
                                    }}
                                  />
                                </IconButton>
                              </div>

                              <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={handleImageMatchQuestionCreation}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className={style.creationBottom}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={questionValue}
                              onChange={handleImageMatchQuestionChange}
                              style={{ width: "100%", height: "100%" }}
                            />
                            {/* <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    multiline
                    rows={5}
                    variant="outlined"
                    onChange={(e) => {
                      setQuestionValue(e.target.value);
                    }}
                    value={questionValue}
                  /> */}
                          </div>
                        </div>
                        <div className={style.mainOptionSet}>
                          <div className={style.firstOptionSet}>
                            <div className={style.firstOptionTop}>
                              <MdOutlineQuestionAnswer />
                              <p>Enter your first option image set</p>
                            </div>
                            {firstOptionSetImage.map((first, index) => (
                              <div
                                className={style.firstOptionSetValues}
                                key={index}
                              >
                                {first ? (
                                  <div
                                    onClick={() => handleReplaceImage(index)}
                                  >
                                    <img
                                      src={first}
                                      alt={`Option ${index + 1}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                    />
                                    <input
                                      id={`fileInput_${index}`}
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }} // Hide the input element
                                      onChange={(e) =>
                                        handleImageFirstOptionSet(
                                          index,
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div className={style.ImageInput}>
                                    <>
                                      <TextField
                                        onClick={() =>
                                          handleReplaceImage(index)
                                        } // Trigger the file input when the text field is clicked
                                        placeholder="Choose file"
                                        sx={{ width: "95%" }}
                                        variant="filled"
                                        InputProps={{
                                          readOnly: true, // Make the text field read-only to prevent manual editing
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton edge="end">
                                                <IoMdCloudUpload />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                      <input
                                        type="file"
                                        id={`fileInput_${index}`}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                          handleImageFirstOptionSet(
                                            index,
                                            e.target.files[0]
                                          )
                                        }
                                      />
                                    </>
                                  </div>
                                )}
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() =>
                                    handleDeleteImageMatchOption(index, "first")
                                  }
                                  disabled={firstOptionSetImage.length <= 2}
                                >
                                  <MdDelete />
                                </IconButton>
                              </div>
                            ))}
                            <div className={style.firstOptionAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={() =>
                                  handleImageMatchOptionAdd("first")
                                }
                              >
                                Add first Options
                              </Button>
                            </div>
                          </div>
                          <div className={style.secondOptionSet}>
                            <div className={style.secondOptionTop}>
                              <MdOutlineQuestionAnswer />
                              <p>Enter your second option set</p>
                            </div>
                            {secondOptionSetImageText.map((second, index) => (
                              <div
                                className={style.secondOptionSetValues}
                                key={index}
                              >
                                <TextField
                                  sx={{ width: "95%" }}
                                  required
                                  variant="filled"
                                  value={second}
                                  onChange={(e) =>
                                    handleImageSecondOptionSet(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() =>
                                    handleDeleteImageMatchOption(
                                      index,
                                      "second"
                                    )
                                  }
                                  disabled={
                                    secondOptionSetImageText.length <= 2
                                  }
                                >
                                  <MdDelete />
                                </IconButton>
                              </div>
                            ))}
                            <div className={style.secondOptionAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={() =>
                                  handleImageMatchOptionAdd("second")
                                }
                              >
                                Add second Options
                              </Button>
                            </div>
                          </div>
                          <div className={style.matchAnswerKey}>
                            <div className={style.matchAnswerTop}>
                              <MdOutlineQuestionAnswer />
                              <p>Enter your answer Keys</p>
                            </div>
                            {imageMatchAnswerValues.map((answer, index) => (
                              <div
                                className={style.matchAnswerValues}
                                key={index}
                              >
                                <TextField
                                  sx={{ width: "95%" }}
                                  required
                                  variant="filled"
                                  value={answer}
                                  onChange={(e) =>
                                    handleImageMatchAnswerKey(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() =>
                                    handleDeleteImageMatchAnswer(index)
                                  }
                                  disabled={imageMatchAnswerValues.length <= 2}
                                >
                                  <MdDelete />
                                </IconButton>
                              </div>
                            ))}
                            <div className={style.answerKeyAdd}>
                              <Button
                                variant="contained"
                                endIcon={<MdAddCircleOutline />}
                                color="secondary"
                                onClick={handleAddImageMatchAnswer}
                              >
                                Add Answer
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className={style.explanation}>
                          <h4>Explanation</h4>
                          <div className={style.explanationTextArea}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={explanation}
                              onChange={handleExplanationChange}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* cross word puzzle */}
                    {crossWordPuzzle ? (
                      <div className={style.fillQuestionSection}>
                        <div className={style.questionCreation}>
                          <div className={style.creationTop}>
                            <div className={style.creationTopLeft}>
                              <TbMessageQuestion />
                              <p>Enter your question?</p>
                              <span>*</span>
                            </div>
                            <div className={style.actionButton}>
                              <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={style.settingsIcon}>
                                <IconButton>
                                  <IoSettingsOutline
                                    onClick={() => {
                                      setSettingView(true);
                                    }}
                                  />
                                </IconButton>
                              </div>
                              <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={
                                        handleCrossWordPuzzleQuestionCreation
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className={style.creationBottom}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={questionValue}
                              onChange={handleCrossWordPuzzleQuestionChange}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>

                        <div className={style.optionSet}>
                          <div className={style.optionTop}>
                            <MdOutlineQuestionAnswer />
                            <p>
                              Enter your clues and answer and click the button
                              Select Starting Cell to add in the cells{" "}
                            </p>
                            <span>*</span>
                          </div>
                          <div className={style.optionBottom}>
                            <div className={style.crosswordcontainer}>
                              <div className={style.crosswordpuzzle}>
                                {grid.map((row, rowIndex) => (
                                  <div key={rowIndex} className={style.row}>
                                    {row.map((cell, colIndex) => (
                                      <div
                                        key={`${rowIndex}-${colIndex}`}
                                        className={style.cellContainer}
                                      >
                                        <input
                                          type="text"
                                          maxLength="1"
                                          className={style.cell}
                                          value={cell.cluevalue}
                                          readOnly
                                          onClick={() =>
                                            handleCellClick(rowIndex, colIndex)
                                          }
                                          // onChange={(e) => handleCrossWordPuzzleinputChange(e, rowIndex, colIndex)}
                                        />
                                        {cell.clueNumber && ( // Display clue number only if it exists
                                          <div className={style.clueNumber}>
                                            {cell.clueNumber}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                              <div className={style.PuzzleQuestion}>
                                <div className={style.QuestionAcross}>
                                  <h4>Across</h4>
                                  {cluesAcross.map((clueObj, index) => (
                                    <div
                                      key={index}
                                      className={
                                        style.QuestionAcrossInputContainer
                                      }
                                    >
                                      <span>{clueObj.number}. </span>
                                      <input
                                        type="text"
                                        placeholder="Enter your clue"
                                        value={clueObj.clue}
                                        onChange={(e) =>
                                          handleClueChange(e, index, "across")
                                        }
                                      />
                                      <input
                                        type="text"
                                        placeholder="Enter your answer"
                                        value={clueObj.answer}
                                        onChange={(e) =>
                                          handleClueAnswerChange(
                                            e,
                                            index,
                                            "across"
                                          )
                                        }
                                      />
                                      <Button
                                        sx={{ width: "70%", margin: "4px 0" }}
                                        variant="contained"
                                        onClick={() =>
                                          startSelectingCell(
                                            index,
                                            "across",
                                            clueObj.id
                                          )
                                        }
                                      >
                                        Select Starting Cell
                                      </Button>
                                    </div>
                                  ))}
                                  <Button
                                    sx={{ width: "60%" }}
                                    variant="contained"
                                    endIcon={<MdAddCircleOutline />}
                                    color="secondary"
                                    onClick={() => addClue("across")}
                                  >
                                    Add Across Clue
                                  </Button>
                                </div>
                                <div className={style.QuestionDown}>
                                  <h4>Down</h4>
                                  {cluesDown.map((clueObj, index) => (
                                    <div
                                      key={index}
                                      className={
                                        style.QuestionDownInputContainer
                                      }
                                    >
                                      <span>{clueObj.number}. </span>
                                      <input
                                        type="text"
                                        placeholder="Enter your clue"
                                        value={clueObj.clue}
                                        onChange={(e) =>
                                          handleClueChange(e, index, "down")
                                        }
                                      />
                                      <input
                                        type="text"
                                        placeholder="Enter your answer"
                                        value={clueObj.answer}
                                        onChange={(e) =>
                                          handleClueAnswerChange(
                                            e,
                                            index,
                                            "down"
                                          )
                                        }
                                      />
                                      <Button
                                        sx={{ width: "70%", margin: "4px 0" }}
                                        variant="contained"
                                        onClick={() =>
                                          startSelectingCell(
                                            index,
                                            "down",
                                            clueObj.id
                                          )
                                        }
                                      >
                                        Select Starting Cell
                                      </Button>
                                    </div>
                                  ))}
                                  <Button
                                    sx={{ width: "60%" }}
                                    variant="contained"
                                    endIcon={<MdAddCircleOutline />}
                                    color="secondary"
                                    onClick={() => addClue("down")}
                                  >
                                    Add Down Clue
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={style.explanation}>
                          <h4>Explanation</h4>
                          <div className={style.explanationTextArea}>
                            <ReactQuill
                              modules={modules}
                              formats={formats}
                              theme="snow"
                              value={explanation}
                              onChange={handleExplanationChange}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <div className={style.sectionRight}>
                <div className={style.rightTop}>
                  <div className={style.questionPalette}>
                    {numbers.map((number) => {
                      const questionType = questions[number - 1].type;
                      const styles = generateButtonStyles(questionType);

                      return (
                        <button
                          key={number}
                          onClick={() => {
                            setSelectedQuestionIndex(number - 1);
                            setBlankAnswers({});
                            setLoading(true);
                            setSelectedNumber(number);
                            setInitiallySelectedQuestion(0);
                          }}
                          disabled={
                            number === selectedNumber ||
                            number === initiallySelectedQuestion
                          }
                          style={
                            number === selectedNumber
                              ? {
                                  ...styles,
                                  border: "2px solid #a0d77c",
                                  color: "#57aa3f",
                                  background: "#e0fbdb",
                                }
                              : number === initiallySelectedQuestion
                              ? {
                                  ...styles,
                                  border: "2px solid #a0d77c",
                                  color: "#57aa3f",
                                  background: "#e0fbdb",
                                }
                              : styles
                          }
                        >
                          {number}
                        </button>
                      );
                    })}
                  </div>
                </div>
                <div className={style.rightBottom}>
                  <div className={style.colorPallet}>
                    <div className={style.colorValue}>
                      <p>Fill in the blank</p>
                      <button
                        style={{
                          background: "#fffce5",
                          color: "#c7d13a",
                          border: "2px solid #d7d67c",
                        }}
                      ></button>
                    </div>
                    <div className={style.colorValue}>
                      <p>Multiple choice question</p>
                      <button
                        style={{
                          background: "#f8eafe",
                          color: "#d466f6",
                          border: "2px solid #e1b7ff",
                        }}
                      ></button>
                    </div>
                    <div className={style.colorValue}>
                      <p>Descriptive</p>
                      <button
                        style={{
                          background: "#fbdbf9",
                          color: "#a43faa",
                          border: "2px solid #d77cc2",
                        }}
                      ></button>
                    </div>
                    <div className={style.colorValue}>
                      <p>Match the following</p>
                      <button
                        style={{
                          background: "#e3efff",
                          color: "#007bff",
                          border: "2px solid #b0d2ff",
                        }}
                      ></button>
                    </div>
                    <div className={style.colorValue}>
                      <p>Dictation</p>
                      <button
                        style={{
                          background: "#fce0cc",
                          color: "#e07426",
                          border: "2px solid #f5ab76",
                        }}
                      ></button>
                    </div>
                    {/* <div className={style.colorValue}>
                      <p>Match the following with image</p>
                      <button
                        style={{
                          background: "#c4f2f2",
                          color: "#02d4d4",
                          border: "2px solid #02d4d4",
                        }}
                      ></button>
                    </div> */}
                    {/* <div className={style.colorValue}>
                      <p>Cross Word Puzzle</p>
                      <button
                        style={{
                          background: "#f0fcd4",
                          color: "#abf500",
                          border: "2px solid #abf500",
                        }}
                      ></button>
                    </div> */}
                    <div className={style.colorValue}>
                      <p>Current question</p>
                      <button
                        style={{
                          border: "2px solid #a0d77c",
                          color: "#57aa3f",
                          background: "#e0fbdb",
                        }}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {questionDeleteView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.DeletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setQuestionDeleteView(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete?</p>
              </div>
              <div className={style.deleteButtons}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    setQuestionDeleteView(false);
                    questionDelete();
                  }}
                >
                  Delete
                </button>

                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setQuestionDeleteView(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {settingView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.settingsListing}>
            <div className={style.settingHeader}>
              <div className={style.headerSettingText}>
                <h5>Add your settings</h5>
              </div>

              <div className={style.settingClose}>
                <MdClose
                  onClick={() => {
                    setSettingView(false);
                  }}
                />
              </div>
            </div>

            <div className={style.settingSection}>
              <div className={style.mainSection}>
                {/* <div className={style.sectionValues}>
                  <label htmlFor="">Time</label>
                  <input
                    value={examTime}
                    onChange={(e) => {
                      setExamTime(e.target.value);
                    }}
                    type="text"
                    name=""
                    id=""
                  />
                </div> */}

                {/* <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "#74174e" }}>
                    Comment By Learner
                  </label>
                  <Switch
                    checked={isCheckedLearner}
                    onChange={handleLearnerSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div> */}
              </div>
              {/* <div className={style.mainSection}>
                <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "#74174e" }}>
                    Comment By Mentor
                  </label>
                  <Switch
                    checked={isCheckedMentor}
                    onChange={handleMentorSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "#74174e" }}>
                    Comment By Others
                  </label>
                  <Switch
                    checked={isCheckedOther}
                    onChange={handleOtherSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div> */}
              <div className={style.mainSection}>
                <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "green" }}>
                    Correct Mark
                  </label>
                  <input
                    value={correctMark}
                    onChange={(e) => {
                      setCorrectMark(e.target.value);
                    }}
                    type="number"
                    name=""
                    id=""
                    min="0"
                  />
                </div>
                <div className={style.sectionValues}>
                  <label htmlFor="" style={{ color: "red" }}>
                    Incorrect Mark
                  </label>
                  <input
                    value={inCorrectMark}
                    onChange={(e) => {
                      setInCorrectMark(e.target.value);
                    }}
                    type="number"
                    name=""
                    id=""
                    min="0"
                  />
                </div>
              </div>

              <div className={style.sectionValues}>
                <label htmlFor="">Status</label>
                <FormControl sx={{ width: "100%", margin: "0.5rem 0" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select status"
                    value={assessmentStatus}
                    onChange={(e) => {
                      setAssessmentStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={"draft"}>Draft</MenuItem>
                    <MenuItem value={"publish"}>Publish</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* <div className={style.mainSection}>
                
                <div className={style.sectionValues}></div>
              </div> */}
            </div>
            <div className={style.settingButton}>
              <button
                className={style.add}
                onClick={() => {
                  setSettingView(false);
                  setSettingsValidation(true);
                }}
              >
                Add
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setSettingView(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/* {whiteBoradIconShow  ? (
        <div className={style.chatboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )} */}

      {/* {whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''} */}
      {mediaPopup ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.MediaPopup}>
            <div className={style.MediaPopupHeader}>
              <div className={style.MediaPopupHeaderText}>
                <h5>Attachment Details</h5>
              </div>

              <div className={style.MediaPopupHeaderClose}>
                <AiOutlineClose
                  onClick={() => {
                    setMediaPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.MediaPopupSection}>
              <div className={style.tabSection}>
                <Tabs
                  TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
                  value={selectedTab}
                  onChange={handleTabChange}
                  centered
                >
                  <Tab
                    label="Images"
                    sx={{
                      "&.Mui-selected": {
                        color: "#3e61a3",
                        backgroundColor: "",
                      },
                      fontSize: "1rem",
                      color: "#1a1e5d",
                      margin: "0 .5rem",
                      borderRadius: "10px",
                    }}
                  />
                  <Tab
                    label="Input"
                    sx={{
                      "&.Mui-selected": {
                        color: "#3e61a3",
                        backgroundColor: "",
                      },
                      fontSize: "1rem",
                      color: "#1a1e5d",
                      backgroundColor: "",
                      margin: "0 .5rem",
                      borderRadius: "10px",
                    }}
                  />
                </Tabs>
              </div>
              <Typography>
                {selectedTab === 0 && (
                  <div className={style.ImageGRid}>
                    {mediaCollection && mediaCollection.length > 0
                      ? mediaCollection.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                handleImageFirstOptionSet(
                                  selectedImageOptionIndex,
                                  item.file_url
                                );
                                setMediaPopup(false);
                              }}
                              className={style.Card}
                            >
                              <img src={item.file_url} />
                            </div>
                          );
                        })
                      : ""}
                  </div>
                )}
                {selectedTab === 1 && (
                  <div className={style.MediaPopupInputTab}>
                    <button
                      onClick={() => {
                        const input = document.getElementById(
                          `fileInput_${selectedImageOptionIndex}`
                        );
                        input.click();
                        setMediaPopup(false);
                      }}
                    >
                      Select File
                    </button>
                  </div>
                )}
              </Typography>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Asessments;
