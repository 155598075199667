import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./Events.module.css";
import CardMedia from "@mui/material/CardMedia";
import { GoOrganization } from "react-icons/go";
import { FaRegCalendar } from "react-icons/fa6";
import { baseUrl } from "../Url";

function Events() {
  const navigate = useNavigate();
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/events/list`);
        const data = await response.json();
        // Sort the events by creation date in descending order
        const sortedEvents = data.data.sort((a, b) => new Date(b.created_date.$date) - new Date(a.created_date.$date));
        setEventsData(sortedEvents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCreateClick = () => {
    navigate("/event/creation");
  };

  const handleMoreClick = (eventId) => {
    localStorage.setItem("eventId", eventId);
    navigate("/event/details");
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Duno Events</p>
        </div>
        <div className={style.headerButton}>
          <button onClick={handleCreateClick}>Add Events</button>
        </div>
      </div>

      <div className={style.eventCardsBox}>
        {eventsData.map((event) => (
          <div
            className={style.eventCards}
            key={event.event_id}
            onClick={() => handleMoreClick(event.event_id)}
          >
            <div className={style.cardContainer}>
              <CardMedia
                className={style.cardImage}
                sx={{ height: 200 }}
                image={event.event_image}
              />

              <div className={style.CardContent}>
                <div className={style.eventTitle}>
                  <h2>{event.event_title}</h2>
                </div>

                <div className={style.host}>
                  <GoOrganization />
                  <p> Hosted By: {event.host_name}</p>
                </div>

                <div className={style.date}>
                  <FaRegCalendar />
                  <p>
                    Date:{" "}
                    <span>
                      {new Date(event.event_start_date.$date).toDateString()}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Events;
