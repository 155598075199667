import React, { useState, useRef, useEffect } from "react";
import style from "./EventCreation.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FaRegTrashAlt } from "react-icons/fa";
import { LuImagePlus } from "react-icons/lu";
import { baseUrl } from "../Url";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom"; 


const CustomQuill = styled(ReactQuill)`
  .ql-container {
    border: none;
  }
  .ql-toolbar {
    border: none;
  }
`;

function EventCreation() {
  const [isFocused, setIsFocused] = useState(false);
  const [image, setImage] = useState(null);
  const [hostName, setHostName] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [description, setDescription] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [eventMode, setEventMode] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen2, setSnackbarOpen2] = useState(false);
  const [snackbarMessage2, setSnackbarMessage2] = useState("");
  const [snackbarSeverity2, setSnackbarSeverity2] = useState("success");

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    const storedUserId = localStorage.getItem("userId");
    if (storedUserName && storedUserId) {
      setUserName(storedUserName);
      setUserId(storedUserId);
    }
  }, []);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setImage(null);
  };

  const CreateEvents = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!eventTitle.trim()) {
      setSnackbarMessage("Event title must not be empty.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      isValid = false;
    }

    if (!eventDetails.trim()) {
      setSnackbarMessage2("Event details must not be empty.");
      setSnackbarSeverity2("error");
      setSnackbarOpen2(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("user_name", userName);
      formData.append("user_id", userId);
      formData.append("event_title", eventTitle);
      formData.append("host_name", hostName);
      formData.append("event_discription", description);
      formData.append("event_details", eventDetails);
      formData.append("event_mode", eventMode);
      formData.append("event_venue", eventVenue);
      formData.append("event_start_date", startDate);
      formData.append("event_end_date", endDate);
      if (fileInputRef.current.files[0]) {
        formData.append("event_image", fileInputRef.current.files[0]);
      }

      const response = await fetch(`${baseUrl}/events/add`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      

      if (result.status) {
        alert("Event added successfully!");
        setSnackbarSeverity("success");
        navigate("/events");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to add event: " + result.description);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error adding event:", error);
      setSnackbarMessage("An error occurred while adding the event.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <h3>Add your Event</h3>
        </div>
      </div>
      <div className={style.form}>
        <div className={style.createBox}>
          <form onSubmit={CreateEvents}>
            <div className={style.hostName}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Host Name"
                  variant="outlined"
                  value={hostName}
                  onChange={(e) => setHostName(e.target.value)}
                  required
                />
              </Box>
            </div>

            <div className={style.eventTitle}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Event Title"
                  variant="outlined"
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                  required
                />
              </Box>
            </div>

            <div className={style.discription}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Box>
            </div>

            <div className={style.eventDetialsBox}>
              <p>Details</p>
              <div
                className={`${style.eventDetails} ${
                  isFocused ? style.focused : ""
                }`}
              >
                <div className={style.quillBox}>
                  <CustomQuill
                    className={style.quill}
                    theme="snow"
                    value={eventDetails}
                    onChange={setEventDetails}
                    onFocus={() => setIsFocused(true)}
                  />
                </div>
              </div>
            </div>

            <div className={style.modePosterTime}>
              <div className={style.dateTime}>
                <div className={style.modeContainer}>
                  <div className={style.modeMenu}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Select Mode
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={eventMode}
                        label="Select Mode"
                        onChange={(e) => setEventMode(e.target.value)}
                        required
                      >
                        <MenuItem value="">
                          <em>Select mode</em>
                        </MenuItem>
                        <MenuItem value="Offline">Offline</MenuItem>
                        <MenuItem value="Online">Online</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={style.startDate}>
                    <label>Start Date & Time </label>
                    <input
                      type="datetime-local"
                      name="startDate"
                      id="startDate"
                      required
                      placeholder="Start Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className={style.endtDate}>
                    <label>End Date & Time</label>
                    <input
                      type="datetime-local"
                      name="endDate"
                      id="endDate"
                      required
                      placeholder="End Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>

                <div className={style.venueBox}>
                  <div className={style.venueContianer}>
                    <div className={style.venue}>
                      <p>Venue/Links</p>
                      <CustomQuill
                        className={style.quill}
                        theme="snow"
                        value={eventVenue}
                        onChange={setEventVenue}
                        placeholder="Enter venue details or links"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.eventPoster}>
                <div className={style.posterContainer}>
                  <div className={style.uploadBtn}>
                    <input
                      accept="image/*"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                    />
                    <button type="button" onClick={handleButtonClick}>
                      Add Image <LuImagePlus className={style.imgIcon} />
                    </button>
                  </div>
                  {image && (
                    <div className={style.posterImage}>
                      <img src={image} alt="Event Poster" />
                      <FaRegTrashAlt
                        className={style.remove}
                        title="remove"
                        onClick={handleImageRemove}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={style.submitButton}>
              <button type="submit"> Submit Event </button>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen2}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen2(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen2(false)}
          severity={snackbarSeverity2}
          sx={{ width: "100%" }}
        >
          {snackbarMessage2}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EventCreation;
