import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../action/User";
import logo from "../../assets/english wagon.png";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMesssage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.userReducer);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    const data = {
      username: username,
      password: password,
    };
    localStorage.setItem('adminUserName', username);
    dispatch(loginAction(data));
  };

  useEffect(() => {
    if (users?.status === "success") {
      if (users?.data.is_superadmin === true) {
        navigate("/organization");
      } else {
        navigate("/club");
      }
      // Store the current login time in local storage
      const loginTime = new Date().toISOString();
      localStorage.setItem('loginTime', loginTime);
    } else if (users?.message === "Login Failed. Please provide valid credentials.") {
      console.log(users);
      setErrorMesssage('Invalid Credentials');
    } else if (users?.status === "failed") {
      setErrorMesssage("You are not authorised to access this page");
    }
  }, [users, navigate]);

  return (
    <div className={style.Container}>
      <div className={style.Login}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={style.form}>
          <div className={style.loginForm}>
            {errorMessage && <p>{errorMessage}</p>}
            <input
              className={style.input}
              type="text"
              value={username}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              placeholder="Username"
            />
            <div className={style.password}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorMesssage("");
                }}
              />
              {showPassword ? (
                <BsFillEyeSlashFill onClick={togglePasswordVisibility} />
              ) : (
                <BsFillEyeFill onClick={togglePasswordVisibility} />
              )}
            </div>

            <button onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
