import React, { useState, useRef, useEffect } from "react";
import style from "./EventUpdate.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FaRegTrashAlt } from "react-icons/fa";
import { LuImagePlus } from "react-icons/lu";
import { baseUrl } from "../Url";
import { useNavigate } from "react-router-dom"; 

const CustomQuill = styled(ReactQuill)`
  .ql-container {
    border: none;
  }
  .ql-toolbar {
    border: none;
  }
`;

function EventUpdate() {
  const [isFocused, setIsFocused] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [image, setImage] = useState(null);
  const [hostName, setHostName] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [description, setDescription] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [mode, setMode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [venue, setVenue] = useState("");
  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setImage(null);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("event_id", eventData.event_id);
      formData.append("user_id", eventData.user_id);
      formData.append("host_name", hostName);
      formData.append("event_title", eventTitle);
      formData.append("event_discription", description);
      formData.append("event_details", eventDetails);
      formData.append("event_mode", mode);
      formData.append("event_start_date", startDate);
      formData.append("event_end_date", endDate);
      formData.append("event_venue", venue);
      if (fileInputRef.current.files[0]) {
        formData.append("event_image", fileInputRef.current.files[0]);
      }

      const response = await fetch(`${baseUrl}/event/edit`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to update event");
      }
      const data = await response.json();
      alert(data.description);
      navigate("/event/details");
    } catch (error) {
      console.error("Error updating event:", error);
      alert("Failed to update event.");
    }
  };

  useEffect(() => {
    const eventId = localStorage.getItem("eventId");

    if (eventId) {
      const fetchEventData = async () => {
        try {
          const response = await fetch(`${baseUrl}/event/details`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              event_id: eventId,
            }),
          });
          if (!response.ok) {
            throw new Error("Failed to fetch event details");
          }
          const data = await response.json();
          const eventData = data.data;
          setEventData(eventData);
          setHostName(eventData.host_name);
          setEventTitle(eventData.event_title);
          setDescription(eventData.event_discription);
          setEventDetails(eventData.event_details);
          setMode(eventData.event_mode);
          setStartDate(
            new Date(eventData.event_start_date.$date)
              .toISOString()
              .slice(0, 16)
          );
          setEndDate(
            new Date(eventData.event_end_date.$date).toISOString().slice(0, 16)
          );
          setVenue(eventData.event_venue);
          setImage(eventData.event_image);
        } catch (error) {
          console.error("Error fetching event details:", error);
        }
      };

      fetchEventData();
    }
  }, []);

  if (!eventData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <h3>Edit {eventData.event_title}</h3>
        </div>
      </div>
      <div className={style.form}>
        <div className={style.createBox}>
          <div className={style.hostName}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="host-name"
                label="Host Name"
                variant="outlined"
                value={hostName}
                onChange={(e) => setHostName(e.target.value)}
              />
            </Box>
          </div>

          <div className={style.updateEventTitle}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="event-title"
                label="Event Title"
                variant="outlined"
                value={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
              />
            </Box>
          </div>

          <div className={style.updateDescription}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="description"
                label="Description"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </div>

          <div className={style.updateEventDetailsBox}>
            <p>Details</p>
            <div
              className={`${style.eventDetails} ${
                isFocused ? style.focused : ""
              }`}
            >
              <div className={style.quillBox}>
                <CustomQuill
                  className={style.quill}
                  theme="snow"
                  value={eventDetails}
                  onChange={setEventDetails}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              </div>
            </div>
          </div>

          <div className={style.modePosterTime}>
            <div className={style.dateTime}>
              <div className={style.modeContainer}>
                <div className={style.modeMenu}>
                  <FormControl sx={{ width: "100%", marginBottom: "20px" }}>
                    <InputLabel id="mode-select-label">Select Mode</InputLabel>
                    <Select
                      labelId="mode-select-label"
                      id="mode-select"
                      value={mode}
                      label="Select Mode"
                      onChange={(e) => setMode(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Select mode</em>
                      </MenuItem>
                      <MenuItem value="offline">Offline</MenuItem>
                      <MenuItem value="online">Online</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={style.startDate}>
                  <label>Start Date & Time </label>
                  <input
                    type="datetime-local"
                    name="startDate"
                    id="startDate"
                    required
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className={style.endDate}>
                  <label>End Date & Time</label>
                  <input
                    type="datetime-local"
                    name="endDate"
                    id="endDate"
                    required
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              <div className={style.venueBox}>
                <div className={style.venueContainer}>
                  <div className={style.venue}>
                    <p>Venue</p>
                    <CustomQuill
                      className={style.quill}
                      theme="snow"
                      value={venue}
                      onChange={setVenue}
                      placeholder="Enter venue details or links"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={style.updateEventPoster}>
              <div className={style.posterContainer}>
                <div className={style.uploadBtn}>
                  <input
                    accept="image/*"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <button type="button" onClick={handleButtonClick}>
                    Add Image <LuImagePlus className={style.imgIcon} />
                  </button>
                </div>
                {image && (
                  <div className={style.posterImage}>
                    <img src={image} alt="Event Poster" />
                    <FaRegTrashAlt
                      className={style.remove}
                      title="remove"
                      onClick={handleImageRemove}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={style.updateButton}>
            <button onClick={handleUpdate}>Update</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventUpdate;
